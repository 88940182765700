






import {Vue, Component} from 'vue-property-decorator';
import AidePointage from '@/components/Aide/AidePointage.vue';


@Component({
    components: {
        AidePointage
    }
})
export default class extends Vue {}
