













import {Vue, Component} from 'vue-property-decorator';
import md from 'markdown-it';

@Component({})
export default class extends Vue {
    private renderedMarkdown: string | null = null;

    private mounted() {
        if (!this.$options.components) {
            return;
        }

        // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
        const content = require('@/assets/md/AidePointage.md').default;

        const mdit = md({
            breaks: true
        });
        mdit.disable('code');

        this.renderedMarkdown = mdit.render(content);
    }
}
